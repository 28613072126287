.playbutton {
    background-color: transparent;
    border: none;
    padding: 5px;
}
.playbutton:focus {
    outline: none;
}
.playbutton:hover {
    cursor: pointer;
}
.playbutton:hover svg {
    color: white;
}
.playbutton svg {
    width: 100%; 
    height: 100%;
    font-size: 4em;
    color: black;
}
