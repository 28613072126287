h1,
h2,
h3,
h4,
h5 {
  text-align: center;
}

.explanationbox {
  text-align: start;
}

.audiocontrol {
  width: 45px;
  height: 45px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.explanationbox a {
  color: rgb(85, 26, 139);
  text-underline-offset: 2px;
  text-decoration-line: underline;
  text-decoration-style: dotted;
  text-decoration-thickness: 2px;
  line-height: 2;
  font-weight: 700;
}

.explanationtable {
  border-spacing: 0;
  font-weight: 600;
  min-width: 350px;
}

.explanationtable div {
  display: inline-block;
  vertical-align: middle;
}

.explanationtable th, .explanationtable td {
  border-bottom:1px solid #000;
  border-left:1px solid #000;
  padding: 0.5em 1em;
}

.explanationtable tr:last-child td:first-child {
  border-radius: 0 0 0 0.6em;
  
}
.explanationtable tr:last-child td:last-child {
  border-radius: 0 0 0.6em 0;
}
.explanationtable tr:first-child td:first-child {
  border-radius: 0.6em 0 0 0;
  border-top:1px solid #000;
}
.explanationtable tr:first-child td:last-child {
  border-radius: 0 0.6em 0 0;
  border-top:1px solid #000;
}
.explanationtable tr td:last-child{
  border-right:1px solid #000;
}