.pausebutton {
    background-color: transparent;
    border: none;
    padding: 5px;
}
.pausebutton:focus {
    outline: none;
}
.pausebutton:hover {
    cursor: pointer;
}
.pausebutton:hover svg {
    color: white;
}
.pausebutton svg {
    width: 100%; 
    height: 100%;
    font-size: 4em;
    color: black;
}