.lessongrid .sectionbox {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    width: 100%;
    height: 500px;
  }

  .sectionimage {
    width: 200px;
    height: 200px;
  }

  @media (max-width: 1200px) {
    .lessongrid .sectionbox {
      width: 100%;
    }
  }
