h1 {
    margin-top: 32px;
}

.endoflessonbox {
    box-shadow: 4px 4px 2px rgba(50, 50, 50, 0.7);
}

.badge {
    position: absolute;
    width: 64px;
    margin-top: -38px;
    left: 41px;
}