.continuebutton {
    box-shadow: 4px 4px 2px #585858;
    text-transform: none !important;
}

.logintitle {
    color: black;
}

.loginsubtitle {
    color: black;
}