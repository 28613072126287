.circlecontainer {
    position: relative;
    cursor: pointer;
    width: 70px;
    height: 70px;
}

.circleshadow {
    position: absolute;
    width: 100%;
    height: 100%;
    border-radius: 50%;
    background-color: rgb(52, 52, 52);
}

.circlebutton {
    position: absolute;
    width: 100%;
    height: 100%;
    border-radius: 50%;
    color: black;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1; /* to keep the button above the shadow */
}