.cardcontainer {
    height: 450px;
    top: 20px;
    width: 500px;
    margin-left: 16px;
    margin-right: 16px;
    position: relative;
    transform-style: preserve-3d;
    box-sizing: border-box;
    cursor: pointer;
}
.cardcontainer.flipped .front {
    transform: rotateY(180deg);
}
.cardcontainer.flipped .back {
    transform: rotateY(0deg);
}
.cardcontainer .front, .cardcontainer .back {
    display: flex;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
    height: 100%;
    width: 100%;
    box-shadow: 4px 4px 2px rgba(50, 50, 50, 0.7);
    padding: 10px;
    position: absolute;
    border-radius: 10px;
    backface-visibility: hidden;
    transform-style: preserve-3d;
    transition: -webkit-transform ease 500ms;
    transition: transform ease 500ms;
}
.cardcontainer .front {
    z-index: 2;
    transform: rotateY(0deg);
}
.cardcontainer .back {
    transform: rotateY(-180deg);
}
.wordimage {
    display: block;
    width: 160px;
    height: 160px;
}
